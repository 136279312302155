* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

body {
  width: 100%;
  height: 100%;
  background: #28220b;
}

h1 {
  font-family: "Spartan", sans-serif;
}

h2,
h3,
h4,
p,
li {
  font-family: "Bitter", serif;
  letter-spacing: 3px;
}

.slide {
  opacity: 0;
  transition-duration: 2s ease;
}

.slide-active {
  opacity: 1;
  transition-duration: 2s;
  transform: scale(1.08);
}
